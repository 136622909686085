import axios from "axios";
import { Configuration } from "@eai-toolkit/client";

export const axiosInstance = axios.create();

export function getBaseApiURL() {
  return process.env.REACT_APP_API_BASE_URL || window.location.origin;
}

export const apiConfiguration = new Configuration({
  basePath: getBaseApiURL(),
});
