import type { Action, Store, StoreEnhancer } from "redux";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import type { ThunkDispatch } from "redux-thunk";
import { thunk } from "redux-thunk";
import type { History } from "history";
import { createBrowserHistory } from "history";
import type { SnackbarState } from "./snackbar/reducer";
import { snackbar as snackbarReducer } from "./snackbar/reducer";
import type { AuthState } from "./auth/reducer";
import { auth as authReducer } from "./auth/reducer";
import { AuthStorage } from "../local-storage";
import Config from "../config";
import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";

declare global {
  interface Window {
    env: { [key: string]: string };
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

export type StoreState = {
  snackbar: SnackbarState;
  auth: AuthState;
};

interface InitStore {
  store: Store<StoreState>;
  history: History;
}

const store = createStore(
  combineReducers({
    snackbar: snackbarReducer,
    auth: authReducer,
  }),
  {},
  compose(applyMiddleware(thunk)),
);

export type AppDispatch = typeof store.dispatch;

export type ThunkActionCreator<T> = (
  params: T,
) => (
  dispatch: ThunkDispatch<StoreState, unknown, Action<string>>,
) => Promise<void>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<StoreState> = useSelector;

const authStorage = new AuthStorage();

export async function initStore(): Promise<InitStore> {
  const storeInitObj: any = {};

  const history = createBrowserHistory();

  const enhancers: Array<StoreEnhancer> = [];

  if (Config.isDev) {
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
      enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    if (!authStorage.isExpired()) {
      storeInitObj.auth = authStorage.get()!.session;
    } else {
      authStorage.clear();
    }
  }

  return {
    store,
    history,
  };
}

export default store;
