import React from "react";
import { createRoot } from "react-dom/client";

import { initStore } from "./states";

(async function () {
  const { store } = await initStore();
  const appModule = await import("./components/App");
  const App = appModule.default;

  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(<App store={store} />);
})();
