import type { ActionType } from "typesafe-actions";
import { getType } from "typesafe-actions";
import type { LoginProvidersInner, User } from "@eai-toolkit/client";
import { actions } from "./actions";

export interface AuthState {
  providers: LoginProvidersInner[] | null;
  terms: string | null;
  user: User | null;
  isloading: boolean;
  authError: string | null;
  redirect: string | null;
}

const INITIAL_STATE = {
  providers: null,
  terms: null,
  user: null,
  isloading: false,
  authError: null,
  redirect: null,
};

export function auth(
  state: AuthState = INITIAL_STATE,
  action: ActionType<typeof actions>,
): AuthState {
  switch (action.type) {
    case getType(actions.loggedInUser.success): {
      const user = action.payload;
      return {
        ...state,
        user,
      };
    }
    case getType(actions.getTerms.success): {
      const terms = action.payload;
      return { ...state, terms };
    }
    case getType(actions.agreeToTerms.success): {
      const termsAgreed = action.payload;
      const { user } = state;
      if (user === null) {
        return state;
      }
      return { ...state, user: { ...user, termsAgreed } };
    }

    case getType(actions.getProviders.request): {
      return { ...state, isloading: true };
    }

    case getType(actions.getProviders.success): {
      const providers = action.payload;
      return { ...state, isloading: false, providers: providers };
    }

    case getType(actions.getProviders.failure): {
      const error = action.payload;
      let error_message = null;
      if (error.response?.status) {
        if (error.response?.status > 399 && error.response?.status < 500) {
          error_message = "Client error, bad request";
        } else if (
          error.response?.status > 499 &&
          error.response?.status < 600
        ) {
          error_message = "Internal server error, try again later";
        } else {
          error_message = "Unknown error";
        }
      }
      return { ...state, isloading: false, authError: error_message };
    }

    default: {
      return state;
    }
  }
}
