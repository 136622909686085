import { createAction } from "typesafe-actions";
import type { SnackbarMessage } from "../../components/Snackbar";

export const actions = {
  addSnackbarQueueItem: createAction(
    "snackbar/ADD_SNACKBAR_QUEUE_ITEM",
  )<SnackbarMessage>(),
  snackbarQueueItemProcessed: createAction(
    "snackbar/SNACKBAR_QUEUE_ITEM_PROCESSED",
  )<void>(),
};
