import type { AuthState } from "./states/auth/reducer";

/*
  Small wrapper for localStorage to improve the default stringify/parse behaviour
  of it's native API.
*/
class Storage {
  private prefix = "";
  private storage = window.localStorage;

  constructor(prefix?: string, customStorage: any = null) {
    if (prefix) this.prefix = prefix;
    if (customStorage) {
      this.storage = customStorage;
    }
  }

  private makeKey(key: string): string {
    return `${this.prefix}-${key}`;
  }

  get(key: string, parse = true): any | null {
    const val = this.storage.getItem(this.makeKey(key));
    return parse && typeof val !== "undefined" ? JSON.parse(val!) : val;
  }

  set(key: string, val: any, stringify = true): void {
    if (typeof val === "undefined") {
      throw new Error("undefined cannot be used as key value.");
    }

    this.storage.setItem(
      this.makeKey(key),
      stringify ? JSON.stringify(val) : val,
    );
  }

  exists(key: string): boolean {
    return Object.keys(this.storage).includes(this.makeKey(key));
  }

  del(key: string, throwNonExistant = false): void {
    if (throwNonExistant && !this.exists(key)) {
      throw new Error(`No such key: ${key}`);
    }
    this.storage.removeItem(this.makeKey(key));
  }

  clear(): void {
    const prefix = `${this.prefix}-`;

    const keys = Object.entries(this.storage)
      .filter(([key, _]) => key.startsWith(prefix))
      .map(([key, _]) => key.replace(prefix, ""));

    keys.forEach((key) => this.del(key));
  }
}

export interface SerializedAuth {
  expires: number;
  session: AuthState;
}

const ONE_DAY_MILLI = 1000 * 60 * 60 * 24;
export class AuthStorage {
  private sessionTime = ONE_DAY_MILLI;
  private storage: Storage;

  constructor(sessionTime?: number) {
    this.storage = new Storage("webapp");
    if (typeof sessionTime !== "undefined") {
      this.sessionTime = sessionTime;
    }
  }

  set(sessionData: object): void {
    this.storage.set("user-session", {
      expires: Date.now() + this.sessionTime,
      session: sessionData,
    } as SerializedAuth);
  }

  get(): SerializedAuth | null {
    return this.storage.get("user-session");
  }

  clear(): void {
    this.storage.del("user-session");
  }

  isExpired(): boolean {
    if (this.storage.exists("user-session")) {
      const session = this.storage.get("user-session");
      return session.expires <= Date.now();
    }

    return true;
  }
}
