const nodeEnv = process.env.NODE_ENV;
const isLocalDev = process.env.REACT_APP_LOCAL_DEV === "true";

const Config = {
  enviro: nodeEnv,
  isProd: nodeEnv === "production",
  isDev: nodeEnv === "development",
  isLocalDev,
};

export default Config;
